<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<style lang="scss">
@import "@/assets/css/normalize.css";
@import "@/assets/css/style.css";
</style>
<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
    beforeCreate() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!window.location.pathname.includes('login') && !user) {
            window.location.href = "/login";
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'login') + '-layout';
        }
    },
    components: {
        AuthLayout, MainLayout
    }
}
</script>
